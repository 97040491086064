import React, { Fragment } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const ArtContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  text-align: center;
  grid-gap: 20px;
  .imgContainer {
    position: relative;
    min-height: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.Original {
      &:hover {
        .gatsby-image-wrapper {
          opacity: 0.2;
        }
        .price {
          opacity: 1;
        }
      }
    }
    .gatsby-image-wrapper {
      position: initial !important;
      transition: opacity 0.2s ease-in-out;
    }
    img {
      z-index: 10;
    }
    .sold {
      position: absolute;
      z-index: 20;
      font-size: 2rem;
      font-weight: 900;
      color: #ff0000;
      text-shadow: 2px 2px 4px #aaa;
      bottom: 0;
      right: 20px;
      transform: rotate(-30deg);
    }
  }
  h2 {
    font-size: 1.4rem;
    color: #339966;
  }
  .price {
    position: absolute;
    font-size: 1.6rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
`

const CommissionArtWork = ({ artwork, imgType }) => (
  <ArtContainer>
    {artwork.map(art => (
      <Fragment key={art.node.id}>
        {art.node.acf.image_type.includes(imgType) && (
          <article>
            <div className={imgType + ' imgContainer art'} key={art.node.featured_media.id}>
              <Img
                fluid={art.node.featured_media.localFile.childImageSharp.fluid}
                alt={art.node.title}
                key={art.node.featured_media.wordpress_id}
              />
              <p className="price">${art.node.acf.price}</p>
            </div>
            <p className="size">{art.node.acf.size}</p>
            <h2>“{art.node.title}”</h2>
            <p className="description">
              {art.node.acf.commission_description ? art.node.acf.commission_description : art.node.acf.description}
            </p>
          </article>
        )}
      </Fragment>
    ))}
  </ArtContainer>
)

export default CommissionArtWork
