import React from 'react'
import Layout from '../components/layout'
import Commissions from '../components/CommissionArtwork'
import { graphql } from 'gatsby'
import ContentBlock from '../components/contentBlock'
import SEO from '../components/seo'

const CommissionWork = ({ data }) => (
  <Layout>
    <SEO title={data.wordpressPage.title} />
    <ContentBlock>
      <h1>{data.wordpressPage.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: data.wordpressPage.content,
        }}
      />
    </ContentBlock>
    <Commissions artwork={data.allWordpressWpArtwork.edges} imgType="Commission" />
  </Layout>
)

export default CommissionWork

export const query = graphql`
  query CommissionQuery {
    allWordpressWpArtwork(sort: { fields: acf___order, order: ASC }) {
      edges {
        node {
          slug
          title
          id
          acf {
            description
            price
            size
            sold
            image_type
            commission_description
          }
          featured_media {
            source_url
            id
            wordpress_id
            localFile {
              childImageSharp {
                fluid(maxHeight: 1500) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(slug: { eq: "commissions-template" }) {
      title
      content
    }
  }
`
